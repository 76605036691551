import React from 'react';
import Home from './Home';
import "./index.css";

function App() {
  return (
    <Home/>
  );
}

export default App;
