import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  Modal,
} from "@mui/material";
import sc from "../assets/sc.png";
import play from "../assets/play.png";
const AppCard = ({
  color,
  title,
  description,
  imgSrc,
  downloadLink,
  playLink,
  barcodeSrc,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Define styles for the title
  const titleStyles = {
    textAlign: "center",
    fontWeight: "bold",
    color: color,
  };

  // Modal styles
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    outline: "none",
  };

  return (
    <Card sx={{ maxWidth: 260, margin: 2, paddingX: 2, paddingY: 1 }}>
      <CardMedia
        component="img"
        image={imgSrc}
        alt={title}
        sx={{ margin: "auto", width: "80%" }}
      />

      <CardContent>
        <hr />
        <Typography
          style={titleStyles}
          gutterBottom
          variant="h5"
          component="div"
        >
          {title}
        </Typography>
        <Typography
          sx={{ height: "70px" }}
          variant="body2"
          color="text.secondary"
        >
          {description}
        </Typography>
        <Box mt={2} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            sx={{ flex: 1 }}
            variant="contained"
            color="primary"
            href={downloadLink}
            download
          >
            Download
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleOpen}
            sx={{ ml: 1, p: 1, minWidth: 20 }}
          >
            <img src={sc} alt="Barcode" style={{ width: 24, height: 24 }} />
          </Button>
        { playLink&& <Button
          variant="outlined"
          color="primary"
          onClick={() => window.open(playLink, '_blank')}
          sx={{ ml: 1, p: 1, minWidth: 20 }}
        >
          <img
            src={play}
            alt="Barcode"
            style={{ width: 22, height: 22, padding: 0 }}
          />
        </Button>}
        </Box>
      </CardContent>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...style, width: 400 }}>
          <Typography variant="h6" component="h2">
            Barcode for {title}
          </Typography>
          <img
            src={barcodeSrc}
            alt="Barcode"
            style={{ width: "100%", margin: "20px 0" }}
          />
          <Button
            variant="contained"
            color="primary"
            href={barcodeSrc}
            download
          >
            Download Barcode
          </Button>
        </Box>
      </Modal>
    </Card>
  );
};

export default AppCard;
