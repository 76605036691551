import React from 'react';
import { Container, Grid, colors } from '@mui/material';
import AppCard from './components/AppCard';
import m1 from "./assets/class.png";
import m2 from "./assets/tank.png";
import m3 from "./assets/tlgo.png";
import b1 from "./assets/classbr.png";
import b2 from "./assets/tankbr.png";
import b3 from "./assets/tlgobr.png";

const Home = () => {
  const apps = [
    {
      title: 'Smart School',
      description: 'The Smart School application serves as a tool to assist schools in class management.',
      imgSrc: m1,
      downloadLink: "https://apk.technolab.ps/SmartSchool/smart-schhool.apk",
      playLink:"",
      barcodeSrc: b1,
      color:"#eaa542"
    },
    {
      title: 'TLGO',
      description: "The application TLGO is a service that enables elevator control via mobile phones.",
      imgSrc: m3,
      downloadLink: 'https://apk.technolab.ps/TLGO/TLGO.apk',
      barcodeSrc: b3,
      playLink:"",
      color:"#459e6b"
    },
    {
      title: 'Smart Tank',
      description: 'The Smart Tank application serves as a tool to helps you track the status of your tank.',
      imgSrc: m2,
      downloadLink: "https://apk.technolab.ps/SmartTank/smart-tank.apk",
      playLink:"",
      barcodeSrc: b2,
      color:"#06bbc6"
    }
  ];

  return (
    <Container>
      <div className="main-heading">
        <h2>Technolab Applications</h2>
      </div>
      <div className="note">
        {/* <p style={{ color: 'red', fontSize: 'larger', fontWeight: 'bold', paddingLeft: '15px' }}>
          Note: These applications are only available for Android and do not work on iPhone.
        </p> */}
        <p style={{ color: 'red', fontSize: '21px', fontWeight: 'bold', paddingRight: '15px', direction: 'rtl', textAlign: 'right' }}>
          ملاحظة: هذه التطبيقات متاحة فقط لأجهزة Android ولا تعمل على أجهزة iPhone.
        </p>
      </div>
      <Grid container justifyContent="center" spacing={4}>
        {apps.map((app, index) => (
          <Grid item key={index}>
            <AppCard {...app} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Home;
